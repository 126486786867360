import { ServicesLoaders } from '@wix/thunderbolt-symbols'
import { NamedSignalsServiceDefinition } from '@wix/thunderbolt-services'
import namedSignalsServiceConfig from './configs/namedSignalsServiceConfig'

export const serviceLoaders: ServicesLoaders = {
	testService: () => import('feature-test-service/service' /* webpackChunkName: "platformTestService" */),
	namedSignalsService: () => import('feature-named-signals-service/service' /* webpackChunkName: "platformNamedSignalsService" */),
}

export const serviceConfigs: { [serviceDefinition: string]: any } = {
	[NamedSignalsServiceDefinition as string]: namedSignalsServiceConfig,
}
