import type { NativeMobileWixCodeSdkHandlers } from '../../types'
import type { NativeMobileWixCodeStorageWixCodeApi } from './types'

export const storageFactory = (
	storageHandlers: NativeMobileWixCodeSdkHandlers['mobileStorage']
): NativeMobileWixCodeStorageWixCodeApi => ({
	local: {
		getItem: storageHandlers.local.getItem,
		setItem: storageHandlers.local.setItem,
		removeItem: storageHandlers.local.removeItem,
		clear: storageHandlers.local.clear,
	},
	memory: {
		getItem: storageHandlers.memory.getItem,
		setItem: storageHandlers.memory.setItem,
		removeItem: storageHandlers.memory.removeItem,
		clear: storageHandlers.memory.clear,
	},
})
