import { defineService } from '@wix/services-manager/helpers'
import { ServiceDefinition } from '@wix/services-manager/types'
import { Signal } from '@wix/services-manager/core-services/signals'

export interface INamedSignalsServiceConfig {
	updateRemote: (name: string, value: any) => Promise<void>
	onRemoteChange: (cb: (name: string, value: any) => void) => Promise<void>
}

export interface INamedSignalsService {
	signal: (initialValue: any, name: string) => Signal<any>
}

export type INamedSignalsServiceDefinition = ServiceDefinition<INamedSignalsService, INamedSignalsServiceConfig>

export const NamedSignalsServiceDefinition = defineService<INamedSignalsService, INamedSignalsServiceConfig>(
	'NamedSignals'
)
