import { FeatureName, GetConfigFn, ServiceName, ServiceUtils } from '@wix/thunderbolt-symbols'

export const getServicesToLoad = (featureNames: Array<FeatureName>): Array<ServiceName> =>
	featureNames.filter((featureName: FeatureName) => isService(featureName)) as Array<ServiceName>

export const isService = (featureName: string) => featureName.endsWith('Service')

export const getConfig = (
	getConfigFn: GetConfigFn | undefined,
	siteConfig: any,
	pageConfig: any,
	serviceUtils?: ServiceUtils
) => (getConfigFn ? getConfigFn({ siteConfig, pageConfig, serviceUtils }) : { ...siteConfig, pageConfig })
