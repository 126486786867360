export default {
	onRemoteChange: (cb: (name: string, value: any) => void) => {
		self.onmessage = ({ data: message }) => {
			if (message.type === 'updateRemote') {
				const { name, value } = message
				cb(name, value)
			}
		}
	},
	updateRemote: (name: string, value: any) => {
		postMessage({ type: 'updateRemote', name, value })
	},
}
