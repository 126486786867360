import type { AnimationData } from 'feature-animations'
import type { IModelsAPI } from '@wix/thunderbolt-symbols'
import type { BootstrapData } from '../../types'
import type { IPlatformAnimations, EffectOptionsTypes, BaseEffectOptions, EffectName } from '../../animations-types'
import type { IViewerHandlers } from '../types'
import { BOOTSTRAP_DATA, MODELS_API, PLATFORM_ANIMATIONS, VIEWER_HANDLERS } from './moduleNames'
import type { TimeEffectData } from 'feature-motion'

type NamedEffect = {
	type: string
	direction?: string | number
	power?: string
	spins?: number
	startFromOffScreen?: boolean
	distance?: { value: number; type: string }
	shape?: string
}

const motionEffectAliasToEffectMap: { [alias: string]: { name: EffectName; params?: (params: Record<string, any>) => Record<string, any> } } = {
	arc: {
		name: 'CurveIn',
		params: ({ direction }) => ({ direction }),
	},
	bounce: {
		name: 'PunchIn',
		params: ({ direction, intensity }) => ({ bounce: intensity, direction: splitCamelCaseIntoWords(direction).join(' ').toLowerCase() }),
	},
	puff: {
		name: 'DropIn',
	},
	zoom: {
		name: 'ExpandIn',
	},
	fade: {
		name: 'FadeIn',
	},
	flip: {
		name: 'FlipIn',
		params: ({ direction }) => ({ direction }),
	},
	float: {
		name: 'FloatIn',
		params: ({ direction }) => ({ direction }),
	},
	fly: {
		name: 'FlyIn',
		params: ({ direction }) => ({ direction }),
	},
	fold: {
		name: 'FoldIn',
		params: ({ direction }) => ({ direction }),
	},
	glide: {
		name: 'GlitchIn',
		params: ({ angle, distance }) => ({ angle, distance }),
	},
	roll: {
		name: 'Reveal',
		params: ({ direction }) => ({ direction }),
	},
	slide: {
		name: 'SlideIn',
		params: ({ direction }) => ({ direction }),
	},
	spin: {
		name: 'SpinIn',
		params: ({ direction, cycles }) => ({ direction, cycles }),
	},
	turn: {
		name: 'CircleIn',
		params: ({ direction }) => ({ direction }),
	},
}

const splitCamelCaseIntoWords = (txt: string) => txt.split(/(?=[A-Z])/)

const PlatformAnimations = ({ viewerHandlers }: IViewerHandlers, bootstrapData: BootstrapData, modelsApi: IModelsAPI): IPlatformAnimations => {
	return {
		async runAnimation({ compId, animationDirection, effectName: effectAlias, effectOptions }) {
			if (bootstrapData.platformEnvData.window.isSSR) {
				return
			}
			const compsToAnimate = modelsApi.isRepeaterTemplate(compId) ? modelsApi.getDisplayedIdsOfRepeaterTemplate(compId) : compId

			const effect = motionEffectAliasToEffectMap[effectAlias] || {}
			const effectName = effect.name || 'FadeIn'
			const params = effect.params ? effect.params(effectOptions) : effectOptions
			const effectData = migrateVeloAnimationData(effectName, animationDirection, effectOptions, compsToAnimate, params)
			return viewerHandlers.motion.runAnimation(effectData, animationDirection)
		},
	}
}

export default {
	factory: PlatformAnimations,
	deps: [VIEWER_HANDLERS, BOOTSTRAP_DATA, MODELS_API],
	name: PLATFORM_ANIMATIONS,
}

const oldFlyInDirectionsMap = {
	top: 0,
	'top-right': 45,
	right: 90,
	'bottom-right': 135,
	bottom: 180,
	'bottom-left': 225,
	left: 270,
	'top-left': 315,
}

// eslint-disable-next-line @typescript-eslint/no-shadow
type MigrateVeloAnimationData = <EffectName extends keyof EffectOptionsTypes, EffectOptions = EffectOptionsTypes[EffectName]>(
	effectName: string,
	direction: string,
	effectOptions: EffectOptions & BaseEffectOptions,
	compId: Array<string> | string,
	params?: Partial<AnimationData['params']>
) => TimeEffectData

// @ts-expect-error
const migrateVeloAnimationData: MigrateVeloAnimationData = (effectName, direction, effectOptions, compId, params: Partial<AnimationData['params']> = {}) => {
	if (params.direction && typeof params.direction === 'string') {
		params.direction = params.direction.replace(' ', '-')
	}

	const targetId = Array.isArray(compId) ? compId : [compId]

	const reversed = direction === 'out'

	const namedEffect: NamedEffect = {
		...params,
		type: effectName,
	}

	switch (effectName) {
		case 'FloatIn':
			namedEffect.direction = namedEffect.direction || 'right'
			break
		case 'ExpandIn':
			namedEffect.power = 'hard'
			namedEffect.direction = 'center'
			break
		case 'SpinIn':
			namedEffect.power = 'hard'
			namedEffect.direction = params?.direction === 'cw' ? 'clockwise' : 'counter-clockwise'
			namedEffect.spins = params?.cycles ?? 2
			break
		case 'FlyIn':
			namedEffect.type = 'GlitchIn'
			namedEffect.power = 'soft'
			namedEffect.startFromOffScreen = true
			// @ts-ignore
			namedEffect.direction = oldFlyInDirectionsMap[params.direction ?? 'right']
			namedEffect.distance = { value: 400, type: 'px' }
			break
		case 'CircleIn':
			namedEffect.direction = namedEffect.direction || 'right'
			break
		case 'CurveIn':
			namedEffect.direction = namedEffect.direction || 'right'
			break
		case 'DropIn':
			namedEffect.power = 'hard'
			break
		case 'FlipIn':
			namedEffect.direction = namedEffect.direction || 'left'
			namedEffect.power = namedEffect.power || 'soft'
			break
		case 'FoldIn':
			namedEffect.direction = namedEffect.direction || 'left'
			namedEffect.power = namedEffect.power || 'hard'
			break
		case 'Reveal':
			if (params.direction === 'center') {
				namedEffect.type = 'ShapeIn'
				namedEffect.shape = 'rectangle'
			} else {
				namedEffect.type = 'RevealIn'
				namedEffect.direction = namedEffect.direction || 'left'
			}
			break
		case 'SlideIn':
			namedEffect.direction = namedEffect.direction || 'left'
			namedEffect.power = namedEffect.power || 'hard'
			break
		case 'PunchIn':
			namedEffect.direction = namedEffect.direction || 'top-left'
			namedEffect.power = params.bounce ?? 'medium'
			break
		case 'GlitchIn':
			namedEffect.power = 'soft'
			namedEffect.direction = params.angle ?? 270
			namedEffect.startFromOffScreen = false
			namedEffect.distance = { value: params.distance ?? 150, type: 'px' }
			break
	}

	return {
		type: 'TimeAnimationOptions',
		...effectOptions,
		duration: effectOptions.duration || 1,
		iterations: 1,
		targetId,
		namedEffect,
		reversed,
	}
}
